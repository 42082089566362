.formContainer {
  background-color: #a7bcff;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.formContainer .formWrapper {
  background-color: white;
  padding: 20px 60px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.formContainer .formWrapper .logo {
  color: #5d5b8d;
  font-weight: bold;
  font-size: 24px;
}
.formContainer .formWrapper .title {
  color: #5d5b8d;
  font-size: 12px;
}
.formContainer .formWrapper form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.formContainer .formWrapper form input {
  padding: 15px;
  border: none;
  width: 250px;
  border-bottom: 1px solid #a7bcff;
}
.formContainer .formWrapper form input::placeholder {
  color: #afafaf;
}
.formContainer .formWrapper form button {
  background-color: #7b96ec;
  color: white;
  padding: 10px;
  font-weight: bold;
  border: none;
  cursor: pointer;
}
.formContainer .formWrapper form label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #8da4f1;
  font-size: 12px;
  cursor: pointer;
}
.formContainer .formWrapper form label img {
  width: 32px;
}
.formContainer .formWrapper p {
  color: #5d5b8d;
  font-size: 12px;
  margin-top: 10px;
}
.home {
  /* background-color: #a7bcff; */
  height: 89vh;
  /* width: 200vh; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.home .container-local {
  /* border: 1px solid white; */
  /* border-radius: 10px; */
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .container-local, .container-local-lg, .container-local-md, .container-local-sm, .container-local-xl {
    min-width: 100% !important;
  }
}


@media screen and (max-width: 768px) {
  .home .container-local {
    width: 100%;
  }
}
.no-chat-message {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: gray;
  
}

.home .container-local .sidebar {
  flex: 1;
  /* background-color: #3e3c61; */
  position: relative;
  max-width: 25%;
  overflow-y:auto;
  overflow-x:hidden;
}
/* For Webkit-based browsers */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  /* background-color: #f0f0f0; */
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}
/* For Webkit-based browsers */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.home .container-local .sidebar .navbar {
  display: flex;
  align-items: center;
  /* background-color: darkgray; */
  height: 50px;
  padding: 10px;
  justify-content: space-between;
  color: #fff;
}
.home .container-local .sidebar .navbar .logo {
  font-weight: bold;
}
@media screen and (max-width: 768px) {
  .home .container-local .sidebar .navbar .logo {
    display: none;
  }
}
.home .container-local .sidebar .navbar .user {
  display: flex;
  gap: 10px;
}
.home .container-local .sidebar .navbar .user img {
  background-color: #ddddf7;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
}
.home .container-local .sidebar .navbar .user button {
  background-color: #5d5b8d;
  color: #ddddf7;
  font-size: 10px;
  border: none;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .home .container-local .sidebar .navbar .user button {
    position: absolute;
    bottom: 10px;
  }
}
.home .container-local .sidebar .search {
  border-bottom: 1px solid gray;
}
.home .container-local .sidebar .search .searchForm {
  padding: 10px;
}
.home .container-local .sidebar .search .searchForm input {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
}
.home .container-local .sidebar .search .searchForm input::placeholder {
  color: lightgray;
}
.home .container-local .sidebar .userChat {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: white;
  cursor: pointer;
}
.home .container-local .sidebar .userChat:hover {
  background-color: #2f2d52;
}
.home .container-local .sidebar .userChat img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
}
.home .container-local .sidebar .userChat .userChatInfo span {
  font-size: 18px;
  font-weight: 500;
}
.home .container-local .sidebar .userChat .userChatInfo p {
  font-size: 14px;
  color: lightgray;
}
.home .container-local .chat {
  flex: 2;
}
.home .container-local .chat .chatInfo {
  height: 50px;
  background-color: rgb(67, 208, 255);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  color: lightgray;
  display:none;
}
.home .container-local .chat .chatIcons {
  display: flex;
  gap: 10px;
}
.home .container-local .chat .chatIcons img {
  height: 24px;
  cursor: pointer;
}
.home .container-local .chat .messages {
  background-color: #fff;
  padding: 10px;
  height: calc(100% - 50px);
  overflow-x: hidden;
}
.home .container-local .chat .messages .message {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}
.home .container-local .chat .messages .message .messageInfo {
  display: flex;
  flex-direction: column;
  color: gray;
  font-weight: 300;
}
.home .container-local .chat .messages .message .messageInfo img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}
.home .container-local .chat .messages .message .messageContent {
  max-width: 80%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.home .container-local .chat .messages .message .messageContent p {
  background-color: #E4E6EB;
  padding: 10px 20px;
  border-radius: 0px 10px 10px 10px;
  max-width: max-content;
}
.home .container-local .chat .messages .message .messageContent img {
  width: 50%;
}
.home .container-local .chat .messages .message.owner {
  flex-direction: row-reverse;
}
.home .container-local .chat .messages .message.owner .messageContent {
  align-items: flex-end;
}
.home .container-local .chat .messages .message.owner .messageContent p {
  background-color: #8da4f1;
  color: white;
  border-radius: 10px 0px 10px 10px;
}
.home .container-local .chat .messages .message.agent .messageContent p {
  background-color: rgb(67, 208, 255);
  padding: 10px 20px;
  border-radius: 0px 10px 10px 10px;
  max-width: max-content;
  color: white;
}
.home .container-local .chat .input {
  height: 50px;
  background-color: #f4f7f9;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home .container-local .chat .input input {
  width: 100%;
  border: none;
  outline: none;
  color: #2f2d52;
  background-color: #f4f7f9;
  font-size: 18px;
}
.home .container-local .chat .input input::placeholder {
  color: lightgray;
}
.home .container-local .chat .input .send {
  display: flex;
  align-items: center;
  gap: 10px;
}
.home .container-local .chat .input .send img {
  height: 24px;
  cursor: pointer;
}
.home .container-local .chat .input .send button {
  border: none;
  padding: 10px 15px;
  color: white;
  background-color: rgb(67, 208, 255);
  cursor: pointer;
}
